(function() {

// Chart design based on the recommendations of Stephen Few. Implementation
// based on the work of Clint Ivy, Jamie Love, and Jason Davies.
// http://projects.instantcognition.com/protovis/bulletchart/
d3.bullet = function() {
  var orient = "left", // TODO top & bottom
      reverse = false,
      duration = 0,
      ranges = bulletRanges,
      markers = bulletMarkers,
      measures = bulletMeasures,
      width = 380,
      height = 30,
      tickFormat = null;

  // For each small multiple…
  function bullet(g) {
    g.each(function(d, i) {
      var rangez = ranges.call(this, d, i).slice().sort(d3.descending),
          markz = markers.call(this, d, i),
          markerz = markers.call(this, d, i).slice().sort(d3.descending),
          measurez = measures.call(this, d, i).slice().sort(d3.descending),
          g = d3.select(this);

      // Compute the new x-scale.
      var x1 = d3.scale.linear()
          .domain([0, Math.max(rangez[0], markerz[0], measurez[0])])
          .range(reverse ? [width, 0] : [0, width]);

      // Retrieve the old x-scale, if this is an update.
      var x0 = this.__chart__ || d3.scale.linear()
          .domain([0, Infinity])
          .range(x1.range());

      // Stash the new scale.
      this.__chart__ = x1;

      // Derive width-scales from the x-scales.
      var w0 = bulletWidth(x0),
          w1 = bulletWidth(x1);

      // Update the range rects.
      var range = g.selectAll("rect.range")
          .data(rangez);

      range.enter().append("rect")
          .attr("class", function(d, i) { return "range s" + i; })
          .attr("width", w0)
          .attr("height", height)
          .attr("x", reverse ? x0 : 0)
          .attr("width", w1)
          .attr("x", reverse ? x1 : 0);

      range.attr("x", reverse ? x1 : 0)
          .attr("width", w1)
          .attr("height", height);

      // Update the measure rects.
      var measure = g.selectAll("rect.measure")
          .data(measurez);

      measure.enter().append("rect")
          .attr("class", function(d, i) { return "measure s" + i; })
          .attr("width", w0)
          .attr("height", height / 3)
          .attr("x", reverse ? x0 : 0)
          .attr("y", height / 3)
        .transition()
          .duration(duration)
          .attr("width", w1)
          .attr("x", reverse ? x1 : 0);

      measure
        .transition()
          .duration(duration)
          .attr("width", w1)
          .attr("height", height / 3)
          .attr("x", reverse ? x1 : 0)
          .attr("y", height / 3);

      /**
       * Begin code for custom measure tooltips show current sales
       */

      var locationColors = { // Stroke and fill colors for each office
        jacksonville: ['#008EE5', '#66BBEF'],
        orlando: ['#936FEE', '#BEA9F5'],
        tallahassee: ['#FFA435', '#FFC886'],
        tampa: ['#FC5561', '#FD99A0'],
        "west palm beach": ['#00B058', '#66D09B']
      };

      // Create and append a tooltip
      var vizTooltip = d3.select('#sales-goals-chart').append('div')
                            .attr('class', 'tooltip')
                            .style('opacity', 0);

      // Custom tooltips for a measure when mouse in
      measure.on("mouseenter", function(d, i) {

        // Transition to full opacity over 0.3s
        vizTooltip.transition()
                    .duration(300)
                    .style('opacity', 1);

        // Add paragraph holding current sales total
        vizTooltip.html('<p>' + (d * 1000000).formatMoney(2) + '</p>')
                    .style('left', function() {

                      // Check if tooltip will be outside chart and position
                      var tooltipWidth = $('#sales-goals-chart .tooltip').width();
                      if ((d3.event.pageX + tooltipWidth) > $('#sales-goals-chart').width()) {
                        return (d3.event.pageX - tooltipWidth) + 'px';
                      }
                      else {
                        return d3.event.pageX + 'px';
                      }
                    })
                    .style('top', function() {

                      // Check if tooltip will be outside chart and position
                      var tooltipHeight = $('#sales-goals-chart .tooltip').height();
                      if ((d3.event.pageY + tooltipHeight) > height) {
                        return (d3.event.pageY - tooltipHeight) + 'px';
                      }
                      else {
                        return d3.event.pageY + 'px';
                      }
                    });
      });

      // Fade out tooltip on mouse out over 0.3s
      measure.on("mouseleave", function(d, i) {
        vizTooltip.transition()
                    .duration(300)
                    .style('opacity', 0);
      });

      /**
       * End code for custom measure tooltips showing current sales
       */

      // Update the marker lines.
      var marker = g.selectAll("g")
          .data(markerz)
          .enter().append("g")
            .style('text-anchor', 'end');

      marker.append('line')
          .attr("class", "marker")
          .attr("x1", x0)
          .attr("x2", x0)
          .attr("y1", height / 6)
          .attr("y2", height * 5 / 6)
          .attr("x1", x1)
          .attr("x2", x1);

        marker.append('circle')
          .attr("cx", x0)
          .attr("cy", height / 2)
          .attr("r", 12)
          .attr("cx", x1)
          .attr("transform", "translate(0, -1)")
          .attr("fill", "yellow");

        marker.append('circle')
          .attr("cx", x0)
          .attr("cy", height / 2)
          .attr("r", 3)
          .attr("cx", x1)
          .attr("transform", "translate(-6, -3)")
          .attr("fill", "black");

        marker.append('circle')
            .attr("cx", x0)
            .attr("cy", height / 2)
            .attr("r", 3)
            .attr("cx", x1)
            .attr("transform", "translate(6, -3)")
            .attr("fill", "black");

        marker.append('ellipse')
            .attr("cx", x0)
            .attr("cy", height / 2)
            .attr("rx", 8)
            .attr("ry", 2)
            .attr("cx", x1)
            .attr("transform", "translate(0, 4)")
            .attr("fill", "black");

        marker.append('ellipse')
            .attr("cx", x0)
            .attr("cy", height / 2)
            .attr("rx", 8)
            .attr("ry", 2)
            .attr("cx", x1)
            .attr("transform", "translate(0, 2)")
            .attr("fill", "yellow");

      marker.append('text')
          .text("Goal")
          .attr("text-anchor", "middle")
          .attr('x', x1)
          .attr('y', (height / 6) - 2);

      marker.attr("x1", x1)
          .attr("x2", x1)
          .attr("y1", height / 6)
          .attr("y2", height * 5 / 6);

      marker.on("mouseenter", function(d, i) {
        vizTooltip.transition()
                    .duration(500)
                    .style('opacity', 1);
        vizTooltip.html('<p>' + (d * 1000000).formatMoney(2) + '</p>')
                    .style('left', function() {
                      var tooltipWidth = $('#sales-goals-chart .tooltip').width();
                      if ((d3.event.pageX + tooltipWidth) > $('#sales-goals-chart').width()) {
                        return (d3.event.pageX - tooltipWidth) + 'px';
                      }
                      else {
                        return d3.event.pageX + 'px';
                      }
                    })
                    .style('top', function() {
                      var tooltipHeight = $('#sales-goals-chart .tooltip').height();
                      if ((d3.event.pageY + tooltipHeight) > height) {
                        return (d3.event.pageY - tooltipHeight) + 'px';
                      }
                      else {
                        return d3.event.pageY + 'px';
                      }
                    });
      });

      marker.on("mouseleave", function(d, i) {
        vizTooltip.transition()
                    .duration(500)
                    .style('opacity', 0);
      });

      // Compute the tick format.
      var format = tickFormat || x1.tickFormat(8);

      // Update the tick groups.
      var tick = g.selectAll("g.tick")
          .data(x1.ticks(8), function(d) {
            return this.textContent || format(d);
          });

      // Initialize the ticks with the old scale, x0.
      var tickEnter = tick.enter().append("g")
          .attr("class", "tick")
          .attr("transform", bulletTranslate(x0))
          .style("opacity", 1e-6);

      tickEnter.append("line")
          .attr("y1", height)
          .attr("y2", height * 7 / 6);

      tickEnter.append("text")
          .attr("text-anchor", "middle")
          .attr("dy", "1em")
          .attr("y", height * 7 / 6)
          .text(format);

      // Transition the entering ticks to the new scale, x1.
      tickEnter.attr("transform", bulletTranslate(x1))
          .style("opacity", 1);

      // Transition the updating ticks to the new scale, x1.
      var tickUpdate = tick.attr("transform", bulletTranslate(x1))
          .style("opacity", 1);

      tickUpdate.select("line")
          .attr("y1", height)
          .attr("y2", height * 7 / 6);

      tickUpdate.select("text")
          .attr("y", height * 7 / 6);

      // Transition the exiting ticks to the new scale, x1.
      tick.exit().attr("transform", bulletTranslate(x1))
          .style("opacity", 1e-6)
          .remove();
    });
    d3.timer.flush();
  }

  // left, right, top, bottom
  bullet.orient = function(x) {
    if (!arguments.length) return orient;
    orient = x;
    reverse = orient == "right" || orient == "bottom";
    return bullet;
  };

  // ranges (bad, satisfactory, good)
  bullet.ranges = function(x) {
    if (!arguments.length) return ranges;
    ranges = x;
    return bullet;
  };

  // markers (previous, goal)
  bullet.markers = function(x) {
    if (!arguments.length) return markers;
    markers = x;
    return bullet;
  };

  // measures (actual, forecast)
  bullet.measures = function(x) {
    if (!arguments.length) return measures;
    measures = x;
    return bullet;
  };

  bullet.width = function(x) {
    if (!arguments.length) return width;
    width = x;
    return bullet;
  };

  bullet.height = function(x) {
    if (!arguments.length) return height;
    height = x;
    return bullet;
  };

  bullet.tickFormat = function(x) {
    if (!arguments.length) return tickFormat;
    tickFormat = x;
    return bullet;
  };

  bullet.duration = function(x) {
    if (!arguments.length) return duration;
    duration = x;
    return bullet;
  };

  return bullet;
};

function bulletRanges(d) {
  return d.ranges;
}

function bulletMarkers(d) {
  return d.markers;
}

function bulletMeasures(d) {
  return d.measures;
}

function bulletTranslate(x) {
  return function(d) {
    return "translate(" + x(d) + ",0)";
  };
}

function bulletWidth(x) {
  var x0 = x(0);
  return function(d) {
    return Math.abs(x(d) - x0);
  };
}

Number.prototype.formatMoney = function(c, d, t){
  var n = this,
      c = isNaN(c = Math.abs(c)) ? 2 : c,
      d = d == undefined ? "." : d,
      t = t == undefined ? "," : t,
      s = n < 0 ? "-" : "",
      i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "",
      j = (j = i.length) > 3 ? j % 3 : 0;
     return s + '$' + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
 };

})();
